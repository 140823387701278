// utils/AxiosConfig.js
import axios from 'axios';

// Define la URL base dependiendo del entorno
const baseURL = 'https://api.portafolio.francolemon.duckdns.org'
const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

console.log('Entorno actual:', process.env.NODE_ENV);

export default axiosInstance;
