import React from 'react';

const WorksSection = () => {
  return (
    <section className="relative py-8 sm:py-12 md:py-16 bg-[#001f3f] overflow-hidden" id="works">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-extrabold text-[#00E5BC] text-center mb-4 sm:mb-6 md:mb-8">
          Recent Works
        </h2>

        <p className="text-base sm:text-lg text-[#AFB3B7] text-center mb-8 sm:mb-10 md:mb-12 max-w-3xl mx-auto">
          I am passionate about developing innovative products. Throughout the years, I've explored various 
          creative avenues, bringing ideas to life through the development of websites, video games, and 
          JavaScript libraries. My work reflects a commitment to turning concepts into reality, regardless 
          of the medium.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-10 md:gap-12 relative z-10">
          {/* safewebchecker.com */}
          <a
            href="https://safewebchecker.com"
            target="_blank"
            rel="noreferrer"
            className="relative group"
          >
            <div className="bg-[#0D1A35] rounded-2xl overflow-hidden shadow-3xl transition-transform transform group-hover:scale-105">
              <div className="p-6 sm:p-8 md:p-10">
                <h3 className="text-sm sm:text-md md:text-lg lg:text-xl font-bold text-[#00E5BC] mb-4 sm:mb-6 md:mb-8 text-center">
                  SAFEWEBCHECKER.COM
                </h3>
                <div className="relative h-48 sm:h-64 md:h-80 bg-[#2A356D] rounded-lg flex items-center justify-center overflow-hidden">
                  <img
                    src={require('../assets/images/proyect_1.png')}
                    alt="SAFEWEBCHECKER.COM"
                    className="object-cover w-full h-full transition-transform duration-500 ease-in-out group-hover:scale-110"
                  />
                </div>
              </div>
              <div className="absolute inset-0 bg-[#01989F] opacity-0 transition-opacity duration-500 group-hover:opacity-90 flex items-center justify-center">
                <span className="text-white font-bold text-2xl sm:text-3xl">Go</span>
              </div>
            </div>
          </a>

          {/* weddingfairy.shop */}
          <a
            href="https://weddingfairy.shop"
            target="_blank"
            rel="noreferrer"
            className="relative group"
          >
            <div className="bg-[#0D1A35] rounded-2xl overflow-hidden shadow-3xl transition-transform transform group-hover:scale-105">
              <div className="p-6 sm:p-8 md:p-10">
                <h3 className="text-sm sm:text-md md:text-lg lg:text-xl font-bold text-[#00E5BC] mb-4 sm:mb-6 md:mb-8 text-center">
                  WEDDINGFAIRY.SHOP
                </h3>
                <div className="relative h-48 sm:h-64 md:h-80 bg-[#2A356D] rounded-lg flex items-center justify-center overflow-hidden">
                  <img
                    src={require('../assets/images/weddingfairy.png')}
                    alt="Frontend Store"
                    className="object-cover w-full h-full transition-transform duration-500 ease-in-out group-hover:scale-110"
                  />
                </div>
              </div>
              <div className="absolute inset-0 bg-[#01989F] opacity-0 transition-opacity duration-500 group-hover:opacity-90 flex items-center justify-center">
                <span className="text-white font-bold text-2xl sm:text-3xl">Go</span>
              </div>
            </div>
          </a>
        </div>

        {/* Decorative Elements */}
        <div className="absolute inset-0 pointer-events-none">
          {/* Top Left Circle */}
          <div className="absolute top-0 left-0 w-20 h-20 sm:w-32 sm:h-32 bg-[#01679C] rounded-full opacity-25"></div>
          {/* Bottom Right Circle */}
          <div className="absolute bottom-0 right-0 w-24 h-24 sm:w-48 sm:h-48 bg-[#00284C] rounded-full opacity-25"></div>
          {/* Top Right Line */}
          <div className="absolute top-4 sm:top-0 right-0 w-32 sm:w-64 h-1 bg-[#00E5BC] opacity-50 transform rotate-45"></div>
          {/* Bottom Left Line */}
          <div className="absolute bottom-4 sm:bottom-0 left-0 w-32 sm:w-64 h-1 bg-[#AFB3B7] opacity-50 transform -rotate-45"></div>
          {/* Center Square */}
          <div className="absolute top-1/2 left-1/2 w-20 sm:w-40 h-20 sm:h-40 bg-[#01989F] opacity-10 transform -translate-x-1/2 -translate-y-1/2 rotate-12"></div>
        </div>
      </div>
    </section>
  );
};

export default WorksSection;